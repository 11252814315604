// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// FontAwesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';


main {
    padding-top: 30px;
    padding-bottom: 30px;
}

footer {
    color: $gray-900;

    a {
        color: $gray-900;
        text-decoration: underline;

        &:hover {
            color: $gray-700;
        }
    }
}

h1 {
    color: $orange;
    text-transform: uppercase;
    margin-bottom: 30px;
}

h2 {
    color: $gray-700;
    margin-top: 30px;
}

h3 {
    color: $yellow;
}

h4 {
    color: $gray-600;
}

label {
    font-weight: normal;
}

button, a, span {
    &.btn-circle-sm {
        @extend .btn-circle;

        width: 30px;
        height: 30px;

        i {
            font-size: 15px;
            position: relative;
        }
    }

    &.btn-circle-md {
        @extend .btn-circle;

        width: 40px;
        height: 40px;

        i {
            font-size: 20px;
            position: relative;
        }
    }

    &.btn-circle-lg {
        @extend .btn-circle;

        width: 60px;
        height: 60px;

        i {
            font-size: 30px;
            position: relative;
        }
    }
}

.container, .container-fluid {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
}

.card {
    background: darken($gray-100, 2%);
    border: none;
}

.form-error {
    color: $danger;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-circle {
    display: inline-block;
    box-sizing: border-box;
    font-size: 0.8em;
    border-radius: 50%;
    text-align: center;
    color: #fff;

    &.btn{
        border: 0;
        padding: 0;
    }

    &:hover {
        color: #fff;
    }
}

.breadcrumb {
    border: none;
    background: none;
    margin-bottom: 5px;
    padding: 0;
    font-size: 0.8rem;
}

.text-with-image {
    @media only screen and (min-width : 768px) {
        & > .text-col {
            padding-right: 15px;
            border-right: 1px dashed $gray-500;
        }

        & > .image-col {
            padding-left: 15px;
        }
    }
}

#app-logo {
    position: relative;
    top: 5px;
    left: 5px;
}

#app-bandeau {
    background-image: url("/images/bandeau.jpg");
    background-color: white;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: cover;
    height: 400px;
    // width: 1140px;
    // height: 354px;
    // margin-top: 15px;
}

#app-menu {
    padding: 0;
    //background: #BBBBBB;

    a.nav-link {
        padding: 0.5rem 1.25rem;
    }

    .nav-item {
        align-self: center;
        text-align: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        line-height: 1.1;

        a:not(.dropdown-item) {
            color: white;
        }

        .dropdown-item {
            text-transform: none;
        }
    }

    .dropdown-menu {
        border: none;
        border-radius: 0;
        background: $dark;
        color: white;
    }

    .dropdown-item {
        padding: 0.5rem 1.5rem;
        color: white;

        &:hover, &:focus {
            background: $gray-600;
        }
    }
}
