// Body
$body-bg: white;

$navbar-light-color: black;

// Typography
$font-family-sans-serif: 'Noto Sans', Arial, sans-serif;
//$font-size-base: 1rem;
//$line-height-base: 1.6;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$orange: #ED9011;
$yellow: #FDCC00;

$primary: #025c77;
$success: #42AD5C;
$info: darken($orange, 3%);
$warning: $yellow;

$grid-gutter-width: 20px;
$card-group-margin: $grid-gutter-width;

$breadcrumb-divider: quote(">");
